.loading, .break, .holding, .survey, .video {
    transition: transform 1s ease-in-out;
}

.loading-exit, .break-exit, .holding-exit, .survey-exit, .video-exit {
    left: 0;
}
.loading-exit-active, .break-exit-active, .holding-exit-active, .survey-exit-active, .video-exit-active {
    left: -3000px;
    transition: left 1500ms;
}

::-webkit-scrollbar{
    width:5px;
    height: 5px;
}
::-webkit-scrollbar-track{
    background: rgba(0, 144, 213, .1);
    border-radius:15px
}
::-webkit-scrollbar-thumb{
    background:rgba(0, 144, 213, .6);
    border-radius:15px
}